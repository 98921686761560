import {
  Button,
  Grid,
  Box,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import theme from "../ColorsConfig";

export default function ResourceItemComponent(props) {
  const navigation = useNavigate();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  const renderButton = (link) => {
    if (link.includes("http")) {
      return (
        <Button
          variant="contained"
          endIcon={<ArrowCircleRightIcon />}
          sx={{ fontSize: 18 }}
        >
          <a
            style={{ color: "white", textDecoration: "none" }}
            href={props.item.link}
          >
            {props.item.button}
          </a>
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          endIcon={<ArrowCircleRightIcon />}
          sx={{ fontSize: 18 }}
          onClick={() => {
            navigation(link);
          }}
        >
          {props.item.button}
        </Button>
      );
    }
  };

  return (
    <Stack
      spacing={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={props.item.image}
        alt={"imageItem"}
        style={{ width: "270px", height: "auto" }}
      />
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          overflowWrap: "break-word",
          fontWeight: "bold",
        }}
      >
        {props.item.title}
      </Typography>
      {props.item.description !== String() && (
        <Typography sx={{ textAlign: "center" }}>
          {props.item.description}
        </Typography>
      )}
      {renderButton(props.item.link)}
    </Stack>
  );
}
