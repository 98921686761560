import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { useSearchParams, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { AppBar, Toolbar, Typography, IconButton, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import RRSSPage from "./RRSSPage";
import EventsPage from "./EventsPage";
import CommunityPage from "./CommunityPage";
import ContactPage from "./ContactPage";
import EducationPage from "./EducationPage";
import AIListPage from "./AIListPage";
import FormsPage from "./FormsPage";
import FileDownloadPage from "./FileDownloadPage";
import FreeResourcesPage from "./FreeResourcesPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AuthTypes } from "../types/AuthTypes";

import theme from "../ColorsConfig";
import TabBarComponent from "../components/TabBarComponent";

// const useStyles = makeStyles({
//   root: {
//     background: "linear( #FFF, #FFF )",
//   },
// });

export default function ChildrenPage(props) {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(String());
  // const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentPage === String()) {
      setCurrentPage(searchParams.get("page"));
      dispatch({ type: AuthTypes.toRoute, payload: String() });
    }
  }, [currentPage, searchParams, dispatch]);

  const renderPage = () => {
    switch (currentPage) {
      case t("needForm"):
        return <FormsPage />;
      case t("education"):
        return <EducationPage />;
      case t("communication"):
        return <RRSSPage />;
      case t("contact"):
        return <ContactPage />;
      case t("community"):
        return <CommunityPage />;
      case t("ailist"):
        return <AIListPage />;
      case t("freeResources"):
        return <FreeResourcesPage />;
      case t("aiMindMap"):
        return <FileDownloadPage />;

      default:
        return <RRSSPage />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Toolbar position="static">
          <IconButton
            onClick={() => {
              navigation(-1);
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
        </Toolbar>
      </Box>
      <Box>{renderPage()}</Box>
    </ThemeProvider>
  );
}
