import React, { useRef } from "react";
import { Button, Grid, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SectionResourcesComponent from "../components/SectionResourcesComponent";
import FreeBooksResources from "../resources/FreeBooksSection.json";
import SpecialProgramsSection from "../resources/SpecialProgramsSection.json";
import ResourcesBooks from "../resources/ResourcesBooks.json";
import FinancialEducationSection from "../resources/FinancialEducationSection.json";
import HealthResources from "../resources/HealthResources.json";
import CulebroTVResources from "../resources/CulebroTVResources.json";
import AIResources from "../resources/AIResources.json";
import { log } from "../helpers/Logger";

export default function FreeResourcesPage() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  console.log(FreeBooksResources);

  const itemsReference = useRef([null, null, null, null, null, null, null]);

  const sections = [
    t("aiResources"),
    t("culebroTVFree"),
    t("freeBooks"),
    t("specialPrograms"),
    t("bookResources"),
    t("financialEducationResources"),
    t("healthResources"),
  ];

  const renderSections = () => {
    return sections.map((_, index) => {
      console.log("====================================");
      console.log(index);
      console.log("====================================");
      return (
        <Grid
          item
          xs={12}
          md={4}
          sx={{ m: "auto", cursor: "pointer" }}
          onClick={() => {
            log("CLICK");
            log(itemsReference.current);
            itemsReference.current[index].scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              bgcolor: "secondary.main",
              color: "white",
              p: "auto",
            }}
            variant="h4"
          >
            {sections[index]}
          </Typography>
        </Grid>
      );
    });
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img
        src={require(`../resources/logo_culebro.png`)}
        alt={"producImage"}
        style={{ height: "auto", width: "20vw" }}
      />
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontWeight: "bold",
        }}
      >
        {t("freeResources")}
      </Typography>
      <Box
        sx={{
          bgcolor: "#173652",
          p: 3,
          m: "auto",
        }}
      >
        <Grid container spacing={3}>
          {renderSections()}
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          ref={(element) => (itemsReference.current[0] = element)}
        >
          <SectionResourcesComponent
            title={t("aiResources")}
            items={AIResources}
          />
        </Grid>

        <Grid
          item
          xs={12}
          ref={(element) => (itemsReference.current[1] = element)}
        >
          <SectionResourcesComponent
            title={t("culebroTVFree")}
            items={CulebroTVResources}
          />
        </Grid>
        <Grid
          item
          xs={12}
          ref={(element) => (itemsReference.current[2] = element)}
        >
          <SectionResourcesComponent
            ref={itemsReference.current[2]}
            title={t("freeBooks")}
            items={FreeBooksResources}
          />
        </Grid>
        <Grid
          item
          xs={12}
          ref={(element) => (itemsReference.current[3] = element)}
        >
          <SectionResourcesComponent
            title={t("specialPrograms")}
            items={SpecialProgramsSection}
          />
        </Grid>
        <Grid
          item
          xs={12}
          ref={(element) => (itemsReference.current[4] = element)}
        >
          <SectionResourcesComponent
            title={t("bookResources")}
            items={ResourcesBooks}
          />
        </Grid>
        <Grid
          item
          xs={12}
          ref={(element) => (itemsReference.current[5] = element)}
        >
          <SectionResourcesComponent
            title={t("financialEducationResources")}
            items={FinancialEducationSection}
          />
        </Grid>
        <Grid
          item
          xs={12}
          ref={(element) => (itemsReference.current[6] = element)}
        >
          <SectionResourcesComponent
            title={t("healthResources")}
            items={HealthResources}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
