import React from "react";

export default function FormsPage() {
  const frame =
    '<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCulebroOficial%2F&tabs=timeline&width="100"&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="1000" height="800px" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>';

  return (
    <div style={{ overflowY: "scroll", height: "100%" }}>
      <div dangerouslySetInnerHTML={{ __html: frame }} />
    </div>
  );
}
