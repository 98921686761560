import React from "react";

export default function FormsPage() {
  const frame =
    '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScxN-zFxeUAOWHVjxm7utvrV5G8bUV4kqOn6yux1xT-RV-QRQ/viewform?embedded=true" width="100%" height="3646" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>';

  return (
    <div style={{ overflowY: "scroll", height: "80vh" }}>
      <div dangerouslySetInnerHTML={{ __html: frame }} />
    </div>
  );
}
