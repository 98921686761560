// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  onSnapshot,
  query,
  updateDoc,
  getDocs,
  writeBatch,
  orderBy,
  where,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
//import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC55OEjN-8YOwVKUaZH8b9rbuVd-PWaSkc",
  authDomain: "culebroapp.firebaseapp.com",
  projectId: "culebroapp",
  storageBucket: "culebroapp.appspot.com",
  messagingSenderId: "88766692574",
  appId: "1:88766692574:web:86ba12f98ec0aee31e964f",
  measurementId: "G-Y6SHD7F71Z",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore();
const auth = getAuth();
//const messaging = getMessaging();

export {
  app,
  db,
  doc,
  getDoc,
  auth,
  writeBatch,
  query,
  getDocs,
  orderBy,
  where,
  setDoc,
  collection,
  addDoc,
  updateDoc,
  onSnapshot,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
};
