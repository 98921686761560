import React from "react";

import UIListItem from "../components/general/UIListItem";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DomainIcon from "@mui/icons-material/Domain";

export default function ContactPage() {
  const links = [
    {
      name: "Sitio Web",
      link: "https://alfredoculebro.com/",
      icon: <DomainIcon />,
      type: 2,
      active: true,
    },
    {
      name: "Email",
      link: "mailto:info@alfredoculebro.com?subject=Ayuda&body=Un gusto saludarles!",
      icon: <EmailIcon />,
      type: 1,
      active: true,
    },
    {
      name: "Servicio al cliente",
      link: "https://wa.me/+524774907734",
      icon: <WhatsAppIcon />,
      index: 3,
      type: 1,
      active: true,
    },
    {
      name: "Teléfono",
      link: "tel:4774907734",
      icon: <LocalPhoneIcon />,
      index: 4,
      type: 1,
      active: true,
    },
  ];

  // const itemSelected = (type, link) => {
  //   if (type === 1) {
  //     window.open(link, "_parent");
  //   }
  // };

  return <UIListItem items={links} />;
}
