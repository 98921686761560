import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  TextField,
  Container,
  CssBaseline,
  Typography,
  AlertTitle,
  Alert,
  Divider,
  Button,
} from "@mui/material";

import UIButton from "../../components/general/UIButton";
import { signUpAction } from "../../actions/AuthenticationAction";
import { PhoneNumberComponent } from "../../components/PhoneNumberComponent";
import { log } from "../../helpers/Logger";

export default function SignUpPage(props) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(Boolean());
  const user = useSelector((state) => state.user);
  const toRoute = useSelector((state) => state.auth.toRoute);
  const errorTitle = useSelector((state) => state.auth.errorTitle);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const [data, setData] = useState({
    name: String(),
    email: String(),
    password: String(),
    phone: {
      code: String(),
      number: String(),
    },
  });

  useEffect(() => {
    if (errorTitle) {
      setLoading(Boolean());
    }

    // if (location.state) {
    //   if (location.state.toRoute !== undefined && toRoute === String()) {
    //     console.log("====================================");
    //     console.log(toRoute);
    //     console.log("====================================");
    //     setToRoute(location.state.toRoute);
    //   }
    // }
  }, [location, errorTitle, toRoute]);

  const handleTextChange = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handlePhoneChange = (name, phone) => {
    setData({
      ...data,
      [name]: phone,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    log(data);
    dispatch(signUpAction(data, toRoute));
  };

  // useEffect(() => {
  //   console.log("====================================");
  //   console.log(user);
  //   console.log("====================================");
  //   if (user.uid !== undefined) {
  //     navigation("/");
  //   }
  // }, [user,navigation]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={require(`../../resources/logo_culebro.png`)}
          alt={"producImage"}
          style={{ height: "auto", width: "50vw" }}
        />
        {/* <Typography variant="h3">Sign in</Typography> */}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {errorTitle && (
            <Alert severity="error">
              <AlertTitle>{errorTitle}</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            {t("register")}
          </Typography>
          <TextField
            onChange={handleTextChange}
            value={data.name}
            margin="normal"
            required
            fullWidth
            id="name"
            label={t("name")}
            name="name"
            autoComplete="name"
            autoFocus
            type="name"
          />
          <TextField
            onChange={handleTextChange}
            value={data.email}
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            type="email"
          />
          <TextField
            onChange={handleTextChange}
            value={data.password}
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type="password"
            id="password"
            autoComplete="password"
          />

          <PhoneNumberComponent
            required
            value={data.phone}
            name="phone"
            label={t("phone")}
            onChange={handlePhoneChange}
          />

          <UIButton
            fullWidth
            type="submit"
            variant="contained"
            loading={loading}
            title={t("signUp")}
            sx={{ mt: 3, bgColor: "primary.main" }}
          />
        </Box>

        <Divider flexItem sx={{ mt: 1 }}>
          <Typography sx={{ fontSize: 21 }}>{"¿Ya tienes cuenta?"}</Typography>
        </Divider>
        <UIButton
          fullWidth
          type="submit"
          variant="outlined"
          title={t("signIn")}
          sx={{ mt: 3, color: "secondary.main", borderColor: "secondary.main" }}
          onClick={() => {
            navigation("/signin");
          }}
        />
      </Box>
    </Container>
  );
}
