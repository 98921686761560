import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import { ListItemAvatar } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Navigator(props) {
  const { t } = useTranslation();

  const mainMenu = [
    {
      id: String(),
      name: t("menuApp"),
      icon: <PhoneIphoneIcon />,
      toRoute: "/",
    },
    {
      id: String(),
      name: t("notifications"),
      icon: <NotificationsIcon />,
      toRoute: "/",
    },
  ];

  const categories = [
    {
      id: "Settings",
      icon: <SettingsIcon />,
      children: [
        { id: t("branches"), toRoute: "settings/branches" },
        {
          id: "Services",
          toRoute: "settings/services/list",
          icon: <ImageIcon />,
        },
        { id: "Shop", icon: <ImageIcon /> },
      ],
    },
  ];

  const item = {
    py: "2px",
    px: 3,
    color: "rgba(255, 255, 255,0.8)",
    "&:hover, &:focus": {
      bgcolor: "rgba(255, 255, 255, 0.08)",
    },
  };

  const itemCategory = {
    boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
    py: 1.5,
    px: 3,
  };

  const { ...other } = props;

  const renderChildrens = (childrens) => {
    return childrens.map((children) => (
      <ListItem disablePadding key={children.identifier}>
        <ListItemButton
          selected={children.active}
          sx={item}
          onClick={() => {
            // history.push(`${children.toRoute}`);
          }}
        >
          <ListItemText>{children.name}</ListItemText>
        </ListItemButton>
      </ListItem>
    ));
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItemAvatar>
          <Avatar
            sx={{ width: 100, height: 100, m: 1, bgcolor: "primary" }}
          ></Avatar>
        </ListItemAvatar>

        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          {process.env.REACT_APP_APP_NAME ?? "????"}
        </ListItem>

        {mainMenu.map((menu) => {
          return (
            <Box key={menu.name}>
              <ListItem sx={{ ...item, ...itemCategory }}>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText
                  onClick={() => {
                    // history.push(menu.toRoute);
                  }}
                >
                  {menu.name}
                </ListItemText>
              </ListItem>
              {menu.childrens && renderChildrens(menu.childrens)}
            </Box>
          );
        })}

        {categories.map(({ id, icon, children }) => (
          <Box key={id} /*sx={{ bgcolor: "#101F33" }}*/>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemIcon sx={{ color: "#fff" }}>{icon}</ListItemIcon>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem>

            {children.map(({ id: childId, icon, active, toRoute }) => (
              <ListItem disablePadding key={childId + "sds"}>
                <ListItemButton
                  selected={active}
                  sx={item}
                  onClick={() => {
                    // history.push(`/${toRoute}`);
                  }}
                >
                  {/* <ListItemIcon>{icon}</ListItemIcon> */}
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
