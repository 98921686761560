import * as React from "react";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import EmailIcon from "@mui/icons-material/Email";
import PeopleIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import HomeIcon from "@mui/icons-material/Home";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HikingIcon from "@mui/icons-material/Hiking";
import HowToRegIcon from "@mui/icons-material/HowToReg";

export default function TabBarComponent(props) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(t("home"));

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.onChange(newValue);
        }}
      >
        <BottomNavigationAction
          value={t("home")}
          label={t("home")}
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          value={t("education")}
          label={t("education")}
          icon={<SchoolIcon />}
        />
        <BottomNavigationAction
          value={t("rrss")}
          label={t("rrss")}
          icon={<ConnectWithoutContactIcon />}
        />
        <BottomNavigationAction
          value={t("contact")}
          label={t("contact")}
          icon={<EmailIcon />}
        />
        <BottomNavigationAction
          value={t("community")}
          label={t("community")}
          icon={<PeopleIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
