import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, onAuthStateChanged } from "../firebase/firebaseConfig";
import PageLoadingComponent from "../components/general/PageLoadingComponent";
// import { getUserService } from "../APIServices/UserAPIService";
import { login, signOutAction } from "../actions/AuthenticationAction";
import { updateInfo } from "../actions/UserActions";
import {
  useLocation,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { log } from "../helpers/Logger";
import { AuthTypes } from "../types/AuthTypes";

const PrivateRouter = ({ children }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logged, setLogged] = useState(null);
  const [user, setUser] = useState(null);
  const register = useSelector((state) => state.auth.registered);
  const toRoute = useSelector((state) => state.auth.toRoute);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      console.log("NANIIIIIII33");
      if (user) {
        let response = {}; //await getUserService(user.uid);
        if (response.code === 0) {
          if (response.data.status === "enabled") {
            dispatch(updateInfo(response.data));
          } else {
            //TODO: SEND ERROR
            dispatch(signOutAction());
          }
        }
        setLogged(true);
        setUser(user);
        dispatch(login(user.uid, user.displayName, user.accessToken));
        console.log("====================================");
        console.log(user);
        console.log("====================================");
      } else {
        setUser(null);
        setLogged(Boolean());
      }
    });
  }, [dispatch]);

  // useEffect(() => {
  //   console.log("NANIIIIIII2222");
  //   if (user && toRoute !== undefined && toRoute !== String()) {
  //     navigate("/");
  //   }
  // }, [register, navigate]);

  let page = searchParams.get("page");
  if (page === undefined) {
    page = String();
  }

  if (logged !== null || logged) {
    if (user === null) {
      if (location.pathname === "/external/result") {
        return children;
      } else {
        dispatch({ type: AuthTypes.toRoute, payload: page });
        return <Navigate to="/signup" replace />;
      }
    }
    return children;
  } else {
    return <PageLoadingComponent />;
  }
};

export default PrivateRouter;
