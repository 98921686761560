import React, { useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import UIButton from "./general/UIButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const sxOptions = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export default function MenuInfoDialogComponent(props) {
  const { t } = useTranslation();

  //const [open, setOpen] = React.useState(props.open);
  // const [menuItem, setMenuItem] = useState();

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    props.onClose();
  };

  const handleSaveClicked = () => {
    //props.addNewAddress(address);
    //handleClose();
  };

  const validateData = (data) => {};

  const textChanged = (e) => {
    props.setMenuItem({
      ...props.menuItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = (productToDelete) => () => {
    // const newAddresses = addresses.filter(
    //   (product) => product.identifier !== productToDelete.identifier
    // );
  };

  const renderIconView = () => {
    console.log(`MENU ITEM: ${props.menuItem ? "1" : "2"}`);
    return props.menuItem.icon ? (
      <Box
        sx={{ weight: 64, height: 64 }}
        component="img"
        src={`data:image/png;base64,${props.menuItem.icon}`}
        alt="icon"
      />
    ) : (
      <ImageIcon />
    );
  };

  return (
    <div>
      {/* DIALOG */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {t("menuItem")}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={sxOptions}>
              {renderIconView()}
            </Grid>

            <Grid item xs={12} sx={sxOptions}>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                name={t("selectFile")}
                onChange={(event) => {
                  console.log("MAYBE FILE");
                }}
              />
            </Grid>

            <Grid item xs={9}>
              <TextField
                name="name"
                id="name"
                value={props.menuItem.name}
                required
                fullWidth
                label={t("name")}
                onChange={textChanged}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label={t("order")}
                id="order"
                name="order"
                variant="outlined"
                fullWidth
                type="number"
                value={props.menuItem.order}
                onChange={textChanged}
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="urlPath"
                id="urlPath"
                value={props.menuItem.urlPath}
                required
                fullWidth
                label={t("URL")}
                onChange={textChanged}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <UIButton title={t("save")} onClick={handleSaveClicked}></UIButton>
        </DialogActions>
      </BootstrapDialog>
      {/* DIALOG */}
    </div>
  );
}
