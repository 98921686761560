import React from "react";
import { useTranslation } from "react-i18next";
import UIListItem from "../components/general/UIListItem";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import {
  CashflowIcon,
  CulebroTVIcon,
  SpotifyIcon,
  AIIcon,
} from "../resources/Icons";

export default function EducationPage() {
  const { t } = useTranslation();
  const links = [
    {
      name: "Audio Libros",
      link: "",
      icon: "",
      active: false,
    },
    {
      name: "Recursos Gratis",
      link: `/privatechildren?page=${t("freeResources")}`,
      icon: <CardGiftcardIcon />,
      active: true,
    },
    {
      name: "I.A. Mapa mental",
      link: `/privatechildren?page=${t("aiMindMap")}`,
      icon: <AIIcon />,
      active: true,
    },
    {
      name: "CashFlow",
      link: "http://cashflow.alfredoculebro.com",
      icon: <CashflowIcon />,
      active: true,
    },
    {
      name: "Thinkific",
      link: "https://alfredoculebro.thinkific.com",
      icon: <PlayCircleIcon />,
      active: true,
    },
    {
      name: "CulebroTV",
      link: "http://culebro.tv",
      icon: <CulebroTVIcon />,
      active: true,
    },
    {
      name: "Webinars",
      link: "",
      icon: "",
      active: false,
    },
    {
      name: "Spotify",
      link: "https://open.spotify.com/show/5vU902GdH2BlJ5TM11s0qi?si=34ff3a9c56c242df",
      icon: <SpotifyIcon />,
      active: true,
    },
    {
      name: "Divergentes",
      link: "",
      icon: "",
      active: false,
    },
    {
      name: "Challenges",
      link: "",
      icon: "",
      active: false,
    },
  ];

  return <UIListItem items={links} />;
}
