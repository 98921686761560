import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  Box,
  TextField,
  Container,
  CssBaseline,
  Typography,
  AlertTitle,
  Alert,
  Button,
} from "@mui/material";

import UIButton from "../../components/general/UIButton";
import { signInAction } from "../../actions/AuthenticationAction";

export default function SignInPage(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(Boolean());
  const [toRoute, setToRoute] = useState(String());
  const errorTitle = useSelector((state) => state.auth.errorTitle);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const [data, setData] = useState({
    email: String(),
    password: String(),
  });

  const { email, password } = data;

  useEffect(() => {
    if (errorTitle) {
      setLoading(Boolean());
    }
    if (location.state) {
      if (location.state.toRoute !== undefined && toRoute === String()) {
        setToRoute(location.state.toRoute);
      }
    }
  }, [location, errorTitle, location.state, toRoute]);

  const handleTextChange = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    dispatch(signInAction(data, toRoute));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={require(`../../resources/logo_culebro.png`)}
          alt={"producImage"}
          style={{ height: "auto", width: "50vw" }}
        />

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {errorTitle && (
            <Alert severity="error">
              <AlertTitle>{errorTitle}</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {t("signin")}
          </Typography>
          <TextField
            required
            fullWidth
            autoFocus
            onChange={handleTextChange}
            value={email}
            margin="normal"
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            type="email"
          />
          <TextField
            required
            fullWidth
            onChange={handleTextChange}
            value={password}
            margin="normal"
            name="password"
            label={t("password")}
            type="password"
            id="password"
            autoComplete="password"
          />

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}

          <UIButton
            fullWidth
            type="submit"
            variant="contained"
            loading={loading}
            title={t("signIn")}
          />
        </Box>
      </Box>
    </Container>
  );
}
