import { Button, Grid, Box, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResourceItemComponent from "./ResourceItemComponent";

export default function SectionResourcesComponent(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const calculateSize = () => {};

  const renderItems = () => {
    return props.items.map((item, index) => {
      return (
        <Grid item xs={12} md={4} key={props.title + index} sx={{ m: "auto" }}>
          <ResourceItemComponent item={item} />
        </Grid>
      );
    });
  };

  return (
    <Stack
      spacing={3}
      sx={{
        m: 2,
      }}
    >
      <Box>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            color: "primary.main",
            fontWeight: "bold",
          }}
        >
          {props.title}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Grid
          container
          spacing={3}
          justify="space-between"
          alignItems="stretch"
        >
          {renderItems()}
        </Grid>
      </Box>
    </Stack>
  );
}
