import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Typography, Link } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Navigator from "../components/general/Navigator";
import Header from "../components/general/Header";
import Box from "@mui/material/Box";
import theme from "../ColorsConfig";
import { useTranslation } from "react-i18next";

const drawerWidth = 256;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function BaseContentPage(props) {
  const { t } = useTranslation();
  //   const [content] = props.content;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {isSmUp ? null : (
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        )}

        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: "block", xs: "none" } }}
        />
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Header
          onDrawerToggle={handleDrawerToggle}
          title={props.title ?? "????"}
        />
        <Box
          component="main"
          sx={{
            flex: 1,
            py: 6,
            px: 4,
            bgcolor: "#eaeff1",
          }}
        >
          {props.children}
        </Box>
        <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
}
