import { Button, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        xs={12}
        onClick={() => {
          navigation(`/privatechildren?page=${t("freeResources")}`);
        }}
      >
        <img
          src={require(`../resources/promo1.png`)}
          alt={"producImage"}
          style={{ height: "90vh", width: "100vw" }}
        />
      </Grid>
    </Grid>
  );
}
