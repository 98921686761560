import React from "react";
import { useTranslation } from "react-i18next";
import UIListItem from "../components/general/UIListItem";
import { MeditationIcon } from "../resources/Icons";
import BookIcon from "@mui/icons-material/Book";
import TelegramIcon from "@mui/icons-material/Telegram";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
//import RssFeedIcon from "@mui/icons-material/RssFeed";

export default function CommunityPage() {
  const { t } = useTranslation();
  const links = [
    {
      name: "Crear logo por IA",
      link: "https://looka.com/logo-maker/",
      icon: "",
      active: true,
    },
    {
      name: "Crear textos IA",
      link: "https://chat.openai.com/",
      icon: "",
      active: true,
    },
    {
      name: "Crear imagenes por IA",
      link: "https://www.canva.com/",
      icon: "",
      active: true,
    },
    {
      name: "Crear presentaciones por IA",
      link: "https://tome.app/",
      icon: "",
      active: true,
    },
  ];

  // const handleItemClicked = (value) => {
  //   window.open(value, "_parent");
  // };

  return <UIListItem items={links} />;
}
