import React from "react";
import { useTranslation } from "react-i18next";
import UIListItem from "../components/general/UIListItem";
import { MeditationIcon } from "../resources/Icons";
import BookIcon from "@mui/icons-material/Book";
import TelegramIcon from "@mui/icons-material/Telegram";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
//import RssFeedIcon from "@mui/icons-material/RssFeed";

export default function CommunityPage() {
  const { t } = useTranslation();
  const links = [
    {
      name: "Blog",
      link: "https://alfredoculebro.com/blog/",
      icon: <BookIcon />,
      active: true,
    },
    {
      name: "Telegram",
      link: "",
      icon: <TelegramIcon />,
      active: false,
    },
    {
      name: "Mañanas Divergentes",
      link: "https://zoom.us/w/707828443",
      icon: <MeditationIcon />,
      active: true,
    },
    {
      name: "¿Como te ayudamos?",
      link: `/children?page=${t("needForm")}`,
      icon: <QuestionMarkIcon />,
      active: true,
    },
  ];

  // const handleItemClicked = (value) => {
  //   window.open(value, "_parent");
  // };

  return <UIListItem items={links} />;
}
