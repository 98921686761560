import * as React from "react";
import Lottie from "lottie-react";
import loading from "../../resources/loadingLottie.json";

import { Box, CircularProgress } from "@mui/material";

export default function PageLoadingComponent(props) {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",

        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie animationData={loading} />;
    </Box>
  );
}
