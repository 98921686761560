import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  InputAdornment,
} from "@mui/material";
import countries from "../models/objects/countries.json";
import { log } from "../helpers/Logger";

export const PhoneNumberComponent = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(Boolean());
  const [currentCode, setCurrentCode] = useState(134);
  const [currentNumber, setCurrentNumber] = useState(String());
  // const [phone, setPhone] = useState({ code: "", number: "" });

  const handleTextChanged = (e) => {
    let value = e.target.value.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
    console.log("====================================");
    console.log(value);
    console.log("====================================");
    setCurrentNumber(String(value));
    notifyChange();
  };

  useImperativeHandle(ref, () => ({
    onClick() {
      document.getElementById("userForm").submitButton.click();
    },
  }));

  const notifyChange = useCallback(() => {
    props.onChange(props.name, {
      code: countries[currentCode].code.split("-")[0],
      number: currentNumber,
    });
  }, [currentCode, currentNumber, props]);

  const renderSelect = () => {
    let options = countries.map((item, index) => {
      return (
        <MenuItem key={index} value={index}>{`${item.country} (+${
          item.code.split("-")[0]
        }) ${item.emoji}`}</MenuItem>
      );
    });
    return (
      <FormControl fullWidth>
        <InputLabel id="grouped-select">{t("countryCode")}</InputLabel>
        <Select
          value={currentCode}
          name="countryCode"
          id="grouped-select"
          label={t("countryCode")}
          renderValue={(value) =>
            `${countries[value].emoji} +${countries[value].code.split("-")[0]}`
          }
          onChange={(event) => {
            setCurrentCode(event.target.value);
            notifyChange();
            // props.onChange(
            //   `+${countries[event.target.value].code}${currentNumber}`
            // );
            // log(event.target.value);
          }}
        >
          {options}
        </Select>
      </FormControl>
    );
  };

  useEffect(() => {
    if (props.disabled) {
      setDisabled(props.disabled);
    }
  }, [props.disabled]);

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {/* <TextField
        fullWidth
        margin="normal"
        disabled={true}
        value={52}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{"+"}</InputAdornment>
          ),
        }}
      /> */}
          {renderSelect()}
        </Grid>
        {/*  */}
        <Grid item xs={8}>
          <TextField
            fullWidth
            placeholder={props.placeholder ? props.placeholder : t("number")}
            value={currentNumber}
            onChange={handleTextChanged}
          />
        </Grid>
      </Grid>
    </Box>
  );
});
