import { ReactComponent as Meditation } from "../img/meditation_symbol.svg";
import { ReactComponent as Spotify } from "../img/spotifyIcon.svg";
import { ReactComponent as Tiktok } from "../img/tiktokIcon.svg";
import { ReactComponent as Cashflow } from "../img/cashflowIcon.svg";
import { ReactComponent as CulebroTV } from "../img/culebroTVIcon.svg";
import { ReactComponent as ClubHouse } from "../img/clubhouseIcon.svg";
import { ReactComponent as AI } from "../img/aiIcon.svg";

import SvgIcon from "@mui/material/SvgIcon";

const MeditationIcon = () => {
  return <SvgIcon component={Meditation} inheritViewBox />;
};

const SpotifyIcon = () => {
  return <SvgIcon component={Spotify} inheritViewBox />;
};

const TiktokIcon = () => {
  return <SvgIcon component={Tiktok} inheritViewBox />;
};

const CashflowIcon = () => {
  return <SvgIcon component={Cashflow} inheritViewBox />;
};

const CulebroTVIcon = () => {
  return <SvgIcon component={CulebroTV} inheritViewBox />;
};

const ClubhouseIcon = () => {
  return <SvgIcon component={ClubHouse} inheritViewBox />;
};

const AIIcon = () => {
  return <SvgIcon component={AI} inheritViewBox />;
};

export {
  MeditationIcon,
  SpotifyIcon,
  TiktokIcon,
  CashflowIcon,
  CulebroTVIcon,
  ClubhouseIcon,
  AIIcon,
};
