import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { auth, onAuthStateChanged } from "../firebase/firebaseConfig";
import BasePage from "../pages/BasePage";
import RRSSPage from "../pages/RRSSPage";
import ContactPage from "../pages/ContactPage";
import EducationPage from "../pages/EducationPage";
import CommunityPage from "../pages/CommunityPage";
import MenuOrderPage from "../pages/MenuOrderPage";
import ChildrenPage from "../pages/ChildrenPage";
import NewsPage from "../pages/NewsPage";
import FormsPage from "../pages/FormsPage";
import SignInPage from "../pages/Auth/SignInPage";
import SignUpPage from "../pages/Auth/SignUpPage";
import HomePage from "../pages/HomePage";
import AIListPage from "../pages/AIListPage";
import LoginPage from "../pages/LoginPage";
import PublicRouter from "./PublicRouter";
import PrivateRouter from "./PrivateRouter";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* <PublicRoute path="login" /> */}
        {/* <PrivateRouter path="/menu" element={<MenuOrderPage />} /> */}
        <Route
          path="/signin"
          element={
            <PublicRouter>
              <SignInPage />
            </PublicRouter>
          }
        />

        <Route
          path="/signup"
          element={
            <PublicRouter>
              <SignUpPage />
            </PublicRouter>
          }
        />

        <Route
          path="/"
          element={
            <PublicRouter>
              <BasePage />
            </PublicRouter>
          }
        />

        <Route
          path="/social"
          element={
            <PublicRouter>
              <RRSSPage />
            </PublicRouter>
          }
        />

        <Route
          path="/privatechildren"
          element={
            <PrivateRouter>
              <ChildrenPage />
            </PrivateRouter>
          }
        />

        <Route path="/ailist" element={<AIListPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/children" element={<ChildrenPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/education" element={<EducationPage />} />
        <Route path="/community" element={<CommunityPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/menu" element={<MenuOrderPage />} />
        <Route path="/form" element={<FormsPage />} />
      </Routes>
    </Router>
  );
};
