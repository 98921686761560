import {
  db,
  collection,
  query as q,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  orderBy,
} from "../firebase/firebaseConfig";

const DB_COLLECTION_CONSTANT = "menuApp";

/// CREATE

export const createMenuItemService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      var request = collection(db, `${DB_COLLECTION_CONSTANT}`);
      var dataReference = await addDoc(request, {
        name: data.name,
        order: data.order,
        icon: data.icon,
        urlPath: data.urlPath,
      });
      resolve({ code: 0, data: dataReference });
    } catch (error) {
      reject({ code: 1, error: error });
    }
  });
};

/// GET

export const getMenuAppService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const query = q(
        collection(db, `${DB_COLLECTION_CONSTANT}`),
        orderBy("order")
      );
      const querySnap = await getDocs(query);
      const aux = [];
      querySnap.forEach((doc) => {
        var data = doc.data();
        data.identifier = doc.id;
        aux.push(data);
      });
      resolve({ code: 0, data: aux });
    } catch (error) {
      console.log(`ERROR: ${error}`);
      reject({ code: 1, error: error });
    }
  });
};

// EDIT

export const editMenuItemService = (identifier, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      var ref = doc(collection(db, `${DB_COLLECTION_CONSTANT}`), identifier);
      await updateDoc(ref, data);
      resolve({ code: 0 });
    } catch (error) {
      reject({ code: 1, error: error });
    }
  });
};
