import "./App.css";
import React, { useEffect, Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./ColorsConfig";
import { AppRouter } from "./routers/AppRouter";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { store } from "./store/store";
import PageLoadingComponent from "./components/general/PageLoadingComponent";

function App() {
  // useEffect(() => {
  //   window
  //     .matchMedia("(prefers-color-scheme: dark)")
  //     .addEventListener("change", (event) => {
  //       const colorScheme = event.matches ? "dark" : "light";
  //       console.log("COLOR" + colorScheme); // "dark" or "light"
  //     });

  //   console.log(
  //     window.matchMedia("(prefers-color-scheme: dark)").matches
  //       ? "dark"
  //       : "light"
  //   );

  //   // Remove listener
  //   return () => {
  //     window
  //       .matchMedia("(prefers-color-scheme: dark)")
  //       .removeEventListener("change", () => {});
  //   };
  // }, []);

  return (
    <Suspense fallback={<PageLoadingComponent />}>
      <Provider store={store}>
        <SnackbarProvider preventDuplicate maxSnack={6}>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </SnackbarProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
