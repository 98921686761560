import React, { lazy } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppBar, Toolbar, Typography } from "@mui/material";

import RRSSPage from "./RRSSPage";
import EventsPage from "./EventsPage";
import CommunityPage from "./CommunityPage";
import HomePage from "./HomePage";
import ContactPage from "./ContactPage";
import EducationPage from "./EducationPage";
import FormsPage from "./FormsPage";

import theme from "../ColorsConfig";
import TabBarComponent from "../components/TabBarComponent";

export default function BasePage(props) {
  const { t } = useTranslation();
  const [currentMenu, setCurrentMenu] = React.useState(t("home"));

  const onChange = (value) => {
    setCurrentMenu(value);
  };

  const getTitle = (title) => {
    switch (title) {
      case 0:
        return "Formulario";
      case 1:
        return "Educación";
      case 2:
        return "Redes Sociales";
      case 3:
        return "Contacto";
      case 4:
        return "Comunidad";
      default:
        return "Talleres";
    }
  };

  const selectContent = (content) => {
    switch (content) {
      case 0:
        return <FormsPage />;
      case 1:
        return <EducationPage />;
      case 2:
        return <RRSSPage />;
      case 3:
        return <ContactPage />;
      case 4:
        return <CommunityPage />;
      default:
        return <RRSSPage />;
    }
  };

  const renderPage = (currentMenu) => {
    switch (currentMenu) {
      case t("home"):
        return <HomePage />;
      case t("education"):
        return <EducationPage />;
      case t("communication"):
        return <RRSSPage />;
      case t("contact"):
        return <ContactPage />;
      case t("community"):
        return <CommunityPage />;
      default:
        return <RRSSPage />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {currentMenu}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      {/* CONTENT */}
      <Box>{renderPage(currentMenu)}</Box>
      {/* TAB BAR */}
      <Box>
        <TabBarComponent onChange={onChange} />
      </Box>
    </ThemeProvider>
  );
}
