/**
 * AuthenticationAction.jsx
 */
import axios from "axios";
import EndPoints from "../config/EndPoints.json";
import { store } from "../store/store";
import { AuthTypes } from "../types/AuthTypes";

import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  createUserWithEmailAndPassword,
} from "../firebase/firebaseConfig";

import { createUserService } from "../apiRestServices/UserAPIService";

/**
 *
 * @param {*} id
 * @param {*} user
 * @param {*} token
 * @returns
 */
export const login = (id, user, token) => {
  return {
    type: AuthTypes.signIn,
    payload: {
      id,
      user,
      token,
    },
  };
};

/**
 *
 * @param {*} data
 * @returns
 */
export const updateInfo = (data) => {
  return {
    type: AuthTypes.updateInfo,
    payload: {
      data,
    },
  };
};

/**
 *
 * @param {*} email
 * @param {*} password
 * @returns
 */
export const signInAction = (data, toRoute) => {
  return (dispatch) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        dispatch({
          type: AuthTypes.logged,
          payload: toRoute,
        });
      })
      .catch((error) => {
        dispatch({
          type: AuthTypes.error,
        });
      });
  };
};

/**
 *
 * @param {*} data
 * @returns
 */
export const signUpAction = (data, toRoute) => {
  return (dispatch) => {
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(({ user }) => {
        updateProfile(auth.currentUser, {
          displayName: `${data.firstName} ${data.lastName}`,
        }).then(async () => {
          let response = await createUserService(user.uid, data);
          console.log("RESPOMSE");
          console.log(response);
          // if (response.code === 0) {
          //   dispatch({
          //     type: AuthTypes.registered,
          //     payload: toRoute,
          //   });
          // }
        });
      })
      .catch((error) => {
        dispatch({
          type: AuthTypes.error,
          error,
        });
      });
  };
};

/**
 *
 * @returns
 */
export const signOutAction = () => {
  return (dispatch) => {
    signOut(auth).then(() => {
      dispatch({
        type: AuthTypes.signOut,
      });
    });
  };
};

/**
 *
 * @returns
 */
export const getUserGeneralInformationAction = () => {
  const state = store.getState();
  const token = state.auth.token;
  return async (dispatch) => {
    try {
      const endpoint = `${process.env.REACT_APP_HOST}${EndPoints.GetUserEndpoint}`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: AuthTypes.updateInfo,
        payload: {
          user: response.data.data,
        },
      });
    } catch (error) {}
  };
};
