import React from "react";
import LinkType from "../enums/LinkType";

import UIListItem from "../components/general/UIListItem";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { SpotifyIcon, TiktokIcon, ClubhouseIcon } from "../resources/Icons";

export default function RRSSPage(props) {
  const links = [
    {
      name: "Facebook",
      link: "https://www.facebook.com/CulebroOficial",
      icon: <FacebookIcon />,
      type: LinkType.none,
      active: true,
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/_culebro",
      icon: <InstagramIcon />,
      type: LinkType.none,
      active: true,
    },
    {
      name: "Youtube",
      link: "https://www.youtube.com/user/alfredoculebro",
      icon: <YouTubeIcon />,
      type: LinkType.none,
      active: true,
    },
    {
      name: "Twitter",
      link: "https://twitter.com/culebro",
      icon: <TwitterIcon />,
      type: LinkType.none,
      active: true,
    },
    {
      name: "ClubHouse",
      link: "https://www.clubhouse.com/join/aprendiendo-a-empren/YuNT0yc6",
      icon: <ClubhouseIcon />,
      type: LinkType.none,
      active: true,
    },
    {
      name: "Spotify",
      link: "https://open.spotify.com/show/5vU902GdH2BlJ5TM11s0qi?si=34ff3a9c56c242df",
      icon: <SpotifyIcon />,
      type: LinkType.web,
      active: true,
    },
    {
      name: "Tiktok",
      link: "https://www.tiktok.com/@alfredoculebro",
      icon: <TiktokIcon />,
      type: LinkType.web,
      active: true,
    },
  ];

  // const renderLink = (option) => {
  //   if (option.type === LinkType.web) {
  //     return (
  //       <Link variant="body2" underline="none" href={option.link}>
  //         {option.name}
  //       </Link>
  //     );
  //   } else {
  //     return <ListItemText primary={option.name} />;
  //   }
  // };

  return <UIListItem items={links} />;
}
