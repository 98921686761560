import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { auth, onAuthStateChanged } from "../firebase/firebaseConfig";
import PageLoadingComponent from "../components/general/PageLoadingComponent";
import { Navigate, useLocation } from "react-router-dom";

const PublicRouter = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const toRoute = useSelector((state) => state.auth.toRoute);
  const [logged, setLogged] = useState(null);
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogged(true);
        setUser(user);
      } else {
        setLogged(Boolean());
      }
    });
  });

  if (logged !== null) {
    if (user) {
      console.log(location.pathname);
      if (toRoute !== String() && toRoute !== undefined) {
        return <Navigate to={`/privatechildren?page=${toRoute}`} replace />;
      } else {
        return children;
      }
    }

    return children;
  } else {
    return <PageLoadingComponent />;
  }
};

export default PublicRouter;
