import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Paper, Container } from "@mui/material";
import Box from "@mui/material/Box";
import theme from "../../ColorsConfig";

export default function PaperBaseComponent(props) {
  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          maxWidth: "md",
          margin: "auto",
          bgcolor: "#FFF",
          overflow: "hidden",
        }}
      >
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            {props.children}
          </Box>
        </Container>
      </Paper>
    </ThemeProvider>
  );
}
