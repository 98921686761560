import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AppBar, Switch } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import ImageIcon from "@mui/icons-material/Image";

const lightColor = "rgba(255, 255, 255, 0.7)";

function Header(props) {
  const { t } = useTranslation();
  const name = "";
  const dispatch = () => {};
  const { onDrawerToggle, title } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMoreMenu, setAnchorMoreMenu] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMoreMenu = Boolean(anchorMoreMenu);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMoreMenu = (event) => {
    setAnchorMoreMenu(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorMoreMenu(null);
  };

  // useEffect(() => {
  //   if (currentTimer == null) {
  //     var timer = setInterval(() => {
  //       setCurrentDate(Date());
  //     }, 1000);
  //     setCurrentTimer(timer);
  //   }
  // }, [currentTimer]);

  // useEffect(() => {
  //   return () => {
  //     clearInterval(currentTimer);
  //   };
  // }, []);

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            {/*  */}
            <Grid item>
              <IconButton onClick={handleClick} size="small">
                <Avatar sx={{ width: 32, height: 32 }}>{}</Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* SECOND BAR */}
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
