import * as React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Divider,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const filterActive = (links) => links.filter((link) => link.active === true);

export default function UIListItem(props) {
  const navigation = useNavigate();

  const button = (option) => {
    if (option.link.includes("http")) {
      return (
        <ListItemButton
          href={option.link}
          target={option.link.includes("http") ? "_blank" : "_self"}
          rel="noreferrer"
          onClick={() => {
            navigation("");
          }}
        >
          {option.name}
        </ListItemButton>
      );
    } else {
      return (
        <ListItemButton
          rel="noreferrer"
          onClick={() => {
            navigation(option.link);
          }}
        >
          {option.name}
        </ListItemButton>
      );
    }
  };

  return (
    <List>
      {filterActive(props.items).map((option) => (
        <Box key={option.name}>
          <ListItem>
            <ListItemIcon>{option.icon}</ListItemIcon>
            {console.log(option.link)}
            {button(option)}
          </ListItem>
          <Divider component="li" />
        </Box>
      ))}
    </List>
  );
}
