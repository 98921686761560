import React, { useCallback, useState, useEffect } from "react";
import { List, CircularProgress, Button } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BaseContentPage from "./BaseContentPage";
import PaperBaseComponent from "../components/general/PaperBaseComponent";
import MenuInfoDialogComponent from "../components/MenuInfoDialogComponent";
import { getMenuAppService } from "../apiRestServices/MenuRestServices";
import { useTranslation } from "react-i18next";

export default function MenuOrderPage() {
  const menuItem = {
    name: String(),
  };
  const { t } = useTranslation();
  const [openMenuItemView, setOpenMenuItemView] = useState(Boolean());
  const [needData, setNeedData] = useState(true);
  const [checked, setChecked] = useState([0]);
  const [menu, setMenu] = useState(null);
  const [menuItemSelected, setMenuItemSelected] = useState(menuItem);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  //GET MENU

  let getData = useCallback(async () => {
    let response = await getMenuAppService();
    if (response.code === 0) {
      setMenu(response.data);
    }
  }, []);

  useEffect(() => {
    if (needData) {
      getData();
      setNeedData(false);
    }
  }, [needData, getData]);

  const handleMenuItemClicked = (value) => {
    const menuItem = menu.find((item) => item.identifier === value);
    setMenuItemSelected(menuItem);
    setOpenMenuItemView(true);
  };

  const renderListItem = (menu) => {
    return menu.map((item) => {
      return (
        <ListItem
          key={item.identifier}
          secondaryAction={
            <IconButton edge="end" aria-label="comments">
              <DeleteForeverIcon />
            </IconButton>
          }
          disablePadding
        >
          <ListItemButton
            onClick={() => {
              handleMenuItemClicked(item.identifier);
            }}
            dense
          >
            <ListItemIcon>
              <DragIndicatorIcon />
            </ListItemIcon>
            <ListItemText id={item} primary={`${item.name}`} />
          </ListItemButton>
        </ListItem>
      );
    });
  };

  const renderView = () => {
    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {menu && renderListItem(menu)}
      </List>
    );
  };

  return (
    <BaseContentPage title={t("menuApp")}>
      <PaperBaseComponent>
        {menu ? renderView() : <CircularProgress />}
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setMenuItemSelected(menuItem);
            setOpenMenuItemView(true);
          }}
        >
          {t("addMenuItem")}
        </Button>
        <MenuInfoDialogComponent
          open={openMenuItemView}
          menuItem={menuItemSelected}
          setMenuItem={setMenuItemSelected}
          onClose={() => {
            setOpenMenuItemView(false);
          }}
          onSave={() => {}}
        />
      </PaperBaseComponent>
    </BaseContentPage>
  );
}
