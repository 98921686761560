import React, { useRef } from "react";
import { Button, Grid, Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SectionResourcesComponent from "../components/SectionResourcesComponent";
import FreeBooksResources from "../resources/FreeBooksSection.json";
import SpecialProgramsSection from "../resources/SpecialProgramsSection.json";
import ResourcesBooks from "../resources/ResourcesBooks.json";
import FinancialEducationSection from "../resources/FinancialEducationSection.json";
import HealthResources from "../resources/HealthResources.json";
import CulebroTVResources from "../resources/CulebroTVResources.json";
import AIResources from "../resources/AIResources.json";
import { log } from "../helpers/Logger";

export default function FileDownloadPage() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  console.log(FreeBooksResources);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Link
        sx={{ fontSize: 27 }}
        href={
          "https://firebasestorage.googleapis.com/v0/b/culebroapp.appspot.com/o/Mapa%20mental.pdf?alt=media&token=ad9ee38d-77ba-4a93-977b-23572e84c49a"
        }
      >
        {"CLICK PARA ABRIR"}
      </Link>
    </Box>
  );
}
